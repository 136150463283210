import mixpanel from 'mixpanel-browser'
import { getAuthKey } from './functions'

export const MixpanelTracking = (eventName, properties) => {
  if (
    getAuthKey() ||
    eventName === MixpanelEventName.visited_template_details ||
    eventName === MixpanelEventName.templateAction ||
    eventName === MixpanelEventName.visited_integration_details ||
    eventName === MixpanelEventName.sign_up
  ) {
    mixpanel.track(eventName, properties)
  }
}

export const MixpanelIdentifyCall = (email) => {
  mixpanel.init(process.env.MIXPANEL_ID, { debug: false })
  mixpanel.identify(email)
}

// Event Name
export const MixpanelEventName = {
  sign_up: 'Sign up',
  sign_in: 'Sign in',
  add_blog: 'Add Blog',
  add_page: 'Add Page',
  add_webhook: 'Add webhook',
  homepage_template: 'Click Template on Home Page',
  homepage_sticky_buttons: 'Click Sticky Button',
  view_all_templates: 'View All Templates',
  visited_template_details: 'Visited Templates Details',
  templateAction: 'Template Action',
  click_template_on_embed: ' Click Templates on Embed',
  click_integration_on_embed: ' Click Integrations on Embed',
  view_all_integrations: 'View All Integrations',
  visited_integration_details: 'Visited Integrations Details',
  integrationAction: 'Integration Action',
  visited_user_management: 'Visited User Management',
  send_invite: 'Send Invite',
  assign_roles: 'Assign Roles',
  remove_user: 'Remove User',
  visited_domain: 'Visited Domain',
  visited_workspace: 'Visited workspace',
  added_domain: 'Added Domain',
  reset_domain: 'Reset Domain',
  configuration_status: 'Configuration Status',
  domain_referral: 'Domain Referral',
  after_template_action_popup: 'After Template Action Popup',
  visited_plans: 'Visited Plans',
  change_in_plan: 'Change in Plan',
  pricing_page: 'Pricing Page',
  launchpad1: 'Launchpad - Added General',
  launchpad2: '	Launchpad - Added Footer Information',
  launchpad3: 'Launchpad - Added Social Links ',
  launchpad_finished: 'Onboarding Finished',
  editor_view: 'View Editor',

  //
  product_interested: 'Product Interested',

  spotlight: 'Spotlight',
}

// Properties Name
export const MixpanelPropertiesName = {
  homepage_template_property: 'Template',
  homepage_sticky_buttons_property: 'Action',
  domain_name: 'Domain name',
  page_slug: 'Page Slug',
  blog_slug: 'Blog Slug',

  user_name: 'User Name',
  user_email: 'User Email',
  tenant_name: 'tenantName',
  template_name: 'Template Name',
  template_slug: 'Template Slug',
  template_action: 'Action',
  template_type: 'Template Type',
  after_template_action: 'Action',

  referrer: 'referrer ',

  integration_name: 'Integration Name',
  integration_slug: 'Integration Slug',
  integration_action: 'Action',

  send_invite_action: 'Status',
  sub_user_name: 'Sub User Name',
  sub_user_email: 'Sub User Email',
  error_message: 'Error Message',
  assign_roles_action: 'Action',

  domain_name: 'Domain Name',
  add_domain_action: 'Status',
  configuration_action: 'Status',
  new_domain_name: 'New Domain Name',
  domain_partner: 'Domain Partner',

  view_super_admin: 'View Super Admin',
  preview_website: 'Preview Website',
  customize_website: 'Customize',

  change_in_plan_action: 'Package',
  change_in_plan_status: 'Action',
  change_in_plan_addons: 'Add Ons',
  change_in_plan_subscription: 'Subscription',

  pricing_page_action: 'Action',
  launchpad_finished_action: 'Action',

  product: 'Product',
}

export const MixpanelPropertiesValues = {
  homepage_sticky_button_whatsapp: 'Whatsapp',
  homepage_sticky_button_community: 'Community',
  homepage_sticky_get_started: 'Get Started',
  homepage_sticky_see_plan: 'Whatsapp',
  homepage_sticky_documentation: 'Documentation',
  homepage_sticky_path: 'Path Name',

  view_demo: 'View Demo',
  apply_template: 'Apply Template',
  duplicate_template: 'Duplicate Template',
  buy_now: 'Buy Now',

  save_integration: 'Save',
  delete_integration: 'Delete',

  send_invite_success: 'Success',
  send_invite_error: 'Failed',

  assign_roles_save: 'Save',
  assign_roles_cancel: 'Cancel',

  add_domain_success: 'Success',
  add_domain_error: 'Failed',
  configuration_status_valid: 'Valid',
  configuration_status_invalid: 'Invalid',

  view_super_admin: 'View Super Admin',
  preview_website: 'Preview Website',
  customize_website: 'Customize',

  upgrade_success: 'Upgrade Success',
  downgrade_success: 'Downgrade Success',
  remove_tradly_branding_addons: 'Remove Tradly Branding',
  special_july_plan: 'July Special',
  Creators_plan: 'Creators',
  Growth_plan: 'Growth',
  Business_plan: 'Business',
  subscription_monthly: 'Monthly',
  subscription_yearly: 'Yearly',

  get_start: 'Get Started',
  buy_now: 'Buy Now',
  speak_to_sales: 'Speak to Sales',
}

// Mixpanel functions

export const EditorMixpanelAction = ({
  userDetails,
  sidebar_name,
  tenantDetails,
  updating_configs_name,
  buttonId,
}) => {
  // ! when this function will run again than need to switch on publish all mixpanel actions also in editor top header page .
  // MixpanelTracking(buttonId === 'save_as_draft' ? 'Saved Draft Editor' : 'Published Live Editor', {
  //   user_name: userDetails?.name,
  //   user_email: userDetails?.email,
  //   editor_sidebar_name: sidebar_name,
  //   user_website_url: `https://${tenantDetails?.domain}`,
  //   [MixpanelPropertiesName.tenant_name]: tenantDetails?.id,
  //   updating_configs_name: updating_configs_name,
  // })
}
