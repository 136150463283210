import '../css/fonts.css'
import '../css/main.css'
import 'focus-visible'
import { useState, useEffect, Fragment } from 'react'
import Header2 from '@/components/Header2'
import { Title } from '@/components/Title'
import Router, { useRouter } from 'next/router'
import ProgressBar from '@badrap/bar-of-progress'
import Head from 'next/head'
import socialCardLarge from '@/img/tradly/Tradly MEta image.png'
import { ResizeObserver } from '@juggle/resize-observer'
import 'intersection-observer'
import { SearchProvider } from '@/components/Search'
import image from '../img/tradly/angel.png'
import TagManager from 'react-gtm-module'
import Header from '@/partials/Header'
import tradly from 'tradly'
import Link from 'next/link'
import Script from 'next/script'
import Whatsapp_button from '@/integrations/Whatsapp_button'
import mixpanel from 'mixpanel-browser'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import axios from 'axios'
import FleetHeader from '@/partials/FleetHeader'
import 'react-image-lightbox/style.css'
import 'rc-slider/assets/index.css'
import { AppProvider } from '@/context_store/AppContext'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import { GlobalPopupProvider } from '@/context/PopupContext'
import store from '@/redux/Store'
import { Provider } from 'react-redux'
import AskButton from '@/components/Buttons/AskButton'
import { getRefreshKey } from 'constant/functions'
import GlobalSidebar from '@/Shared/Sidebar/GlobalSidebar'
import LanguageCurrencyInModal from '@/components/Auth/AdditionalInformation/LanguageCurrencyInModal'

if (typeof window !== 'undefined' && !('ResizeObserver' in window)) {
  window.ResizeObserver = ResizeObserver
}

const progress = new ProgressBar({
  size: 2,
  color: '#38bdf8',
  className: 'bar-of-progress',
  delay: 100,
})

// this fixes safari jumping to the bottom of the page
// when closing the search modal using the `esc` key
if (typeof window !== 'undefined') {
  progress.start()
  progress.finish()
}

Router.events.on('routeChangeStart', () => progress.start())
Router.events.on('routeChangeComplete', () => progress.finish())
Router.events.on('routeChangeError', () => progress.finish())

export default function App({ Component, pageProps, router }) {
  let [navIsOpen, setNavIsOpen] = useState(false)

  // tradly initialization
  tradly.init.config({
    token: '',
    environment: process.env.ENVIRONMENT,
  })
  //mixpanle initialization
  mixpanel.init(process.env.MIXPANEL_ID, { debug: false })

  // user Details and tenant details fetching
  useEffect(() => {
    if (getRefreshKey()) {
      axios
        .get('/api/refresh_token')
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [router])

  //
  useEffect(() => {
    if (!navIsOpen) return
    function handleRouteChange() {
      setNavIsOpen(false)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [navIsOpen])

  const Layout = Component.layoutProps?.Layout || Fragment
  const layoutProps = Component.layoutProps?.Layout
    ? { layoutProps: Component.layoutProps, navIsOpen, setNavIsOpen }
    : {}
  const showHeader = router.pathname !== '/'
  const meta = Component.layoutProps?.meta || {}
  const description =
    meta.metaDescription ||
    meta.description ||
    'Create App without Coding. White label software and builder for Multi Vendor Marketplaces, App Stores, Storefronts, On Demand Delivery. Fast, FREE, and Pay as you grow. A simple no-code tool for start + Headless API for scale. '

  if (router.pathname.startsWith('/examples/')) {
    return <Component {...pageProps} />
  }

  let section =
    meta.section ||
    Object.entries(Component.layoutProps?.Layout?.nav ?? {}).find(([, items]) =>
      items.find(({ href }) => href === router.pathname)
    )?.[0]

  return (
    <>
      <Title suffix="Tradly">{meta.metaTitle || meta.title}</Title>
      <Head>
        <meta name="description" content={description} />
        <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
        <meta key="twitter:site" name="twitter:site" content="@TradlyPlatform" />
        <meta key="twitter:description" name="twitter:description" content={description} />
        <meta
          key="twitter:image"
          name="twitter:image"
          content={`https://tradly.app${socialCardLarge}`}
        />
        <meta key="twitter:creator" name="twitter:creator" content="@TradlyPlatform" />
        <meta key="og:url" property="og:url" content={`https://tradly.app${router.asPath}`} />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:description" property="og:description" content={description} />
        <meta key="og:image" property="og:image" content={`https://tradly.app${socialCardLarge}`} />
        <link rel="alternate" type="application/rss+xml" title="RSS 2.0" href="/feeds/feed.xml" />
        <link rel="alternate" type="application/atom+xml" title="Atom 1.0" href="/feeds/atom.xml" />
        <link rel="alternate" type="application/json" title="JSON Feed" href="/feeds/feed.json" />
      </Head>

      <Provider store={store}>
        <SearchProvider>
          <AppProvider>
            <GlobalPopupProvider>
              {!router.pathname.startsWith('/launchpad') &&
                !router.pathname.startsWith('/editor') &&
                !router.pathname.startsWith('/add_page') &&
                !router.pathname.startsWith('/edit_page') &&
                !router.pathname.startsWith('/workspace') &&
                !router.pathname.startsWith('/signup') &&
                !router.pathname.startsWith('/onboarding') &&
                !router.pathname.startsWith('/embed') &&
                !router.pathname.startsWith('/info') && (
                  <>
                    <LanguageCurrencyInModal />
                    <Header
                      hasNav={Boolean(Component.layoutProps?.Layout?.nav)}
                      navIsOpen={navIsOpen}
                      onNavToggle={(isOpen) => setNavIsOpen(isOpen)}
                      title={meta.title}
                      section={section}
                    />
                  </>
                )}

              {/*  */}
              <Layout {...layoutProps}>
                <Component section={section} {...pageProps} />
              </Layout>
            </GlobalPopupProvider>
            <GlobalSidebar />
          </AppProvider>
        </SearchProvider>
      </Provider>
    </>
  )
}
